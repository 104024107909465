import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { SDKProvider } from '@telegram-apps/sdk-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SDKProvider acceptCustomStyles debug>
      <AppRoot>
        <App />
      </AppRoot>
    </SDKProvider>
   </React.StrictMode>
);

