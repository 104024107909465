const tg = window.Telegram.WebApp;

var data = tg.initData.split("&");
var objTG={};
//parse data
for(var key in data)
{
    objTG[data[key].split("=")[0]] = data[key].split("=")[1]; //console.log(data[key]);
}

var userData
if (objTG.user){
    userData = JSON.parse(decodeURIComponent(objTG.user))
    //console.info('userData',userData)
} else {
    console.info("Telegram Data: Невозможно получить User ID")
}
if (!userData){userData = {id: 0}}
var data = {
    "telegram_id": userData.id,
    "telegram_username": userData.username,
    // "email": email,
    // "product_name": product_name,
    // "currency": currency
}
console.info(data)


const onLoad = () => {
    tg.ready()
    tg.expand()
}

export function useTelegram() {

    // const onClose = () => {
        // tg.close()
    // }

    // const onLoad = () => {
    //     tg.ready()
    //     tg.expand()
    // }

    return {
        // onClose,
        onLoad,
        tg,
        userData,
        // getUserData,
        user: tg.InitDataUnsafe?.user,
        
    }
}