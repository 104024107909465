import React, {useCallback, useEffect, useState} from 'react';
import './Form.css';
import {useTelegram} from "../../hooks/useTelegram";
import {
    postEvent,
  } from '@telegram-apps/sdk-react';
// Import components from the library
import {    Cell, 
            Section, 
            Button, 
            Headline, 
            Input, 
            Steps, 
            Caption, 
            Subheadline,
            InlineButtons,
            Timeline,
            Placeholder,
            List
            // Icon24Notifications,
            // Icon24QR
        } 
        from '@telegram-apps/telegram-ui';
import { InlineButtonsItem } from '@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem';
import { TimelineItem } from '@telegram-apps/telegram-ui/dist/components/Blocks/Timeline/components/TimelineItem/TimelineItem';
import validator from "validator";


const Form = () => {
    const [step, setStep] = useState(1);
    const [product, setProduct] = useState('Подписка на приватный канал');
    const [email, setEmail] = useState('');
    const [emailStatus, setEmailStatus] = useState('default');
    const [emailFooter, setEmailFooter] = useState('');
    const [currencyIndex, setCurrencyIndex] = useState(1);
    const [price, setPrice] = useState({
        "currency": "EUR",
        "amount": 5
    });
    const [priceData, setPriceData] = useState([]);
    const [invoiceDate, setInvoiceData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const {tg, userData} = useTelegram();
    
    const prices = ["468,16 RUB", "5,46 USD", "5 EUR"]
    const errorMsg = "Введите правильный email"
    const errorCurrencyMsg = "Выберите валюту"
    const URL = 'https://api.ildarsupport.shop'
    const TOKEN_PATH = 'token'
    const INVOICE_PATH = '/transactionLink'
    const UPDATE_PATH = '/update'
    const PRICE_PATH = '/getProductPrice'
    
    useEffect(() => {
        postEvent('web_app_ready');
        postEvent('web_app_expand');
        
        const data = {
            product
        }
        fetch(`${URL}${PRICE_PATH}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            // Handle the response data here
            setPriceData(data.price)
        })
        .catch(error => {
            console.log(error)
            // Handle any errors
        });
    }, [])

    const updateContract = async () => {
            const contract_id = invoiceDate.id
            const data = {
                contract_id
            }
            const response = await fetch(`${URL}${UPDATE_PATH}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            const result = await response.json();
            if (await result.length > 0)
                postEvent('web_app_close');
            
      };
    
    
    const nextStep = () => {
        const res = step < 3 ? setStep(step+1) : step;
    }
    
    const previousStep = () => {
        const res = step > 1 ? setStep(step-1) : step;
    }

    const onSendData = useCallback(() => {
        const data = {
            email,
            currencyIndex
        }
        tg.sendData(JSON.stringify(data));
    }, [email, currencyIndex])

    const createInvoice = (data) => {
        fetch(`${URL}${INVOICE_PATH}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setInvoiceData(data)
                nextStep()
                setIsLoading(false)
              // Handle the response data here
            })
            .catch(error => {
                console.log(error)
                setIsLoading(false)
              // Handle any errors
            });
    }


    const onChangeEmail = (e) => {
        // validateEmail(e.target.value)
        setEmail(e.target.value)
    }

    const isButtonSelected = (index) => {
        return currencyIndex === index ? 'bezeled' : 'plain';
    }

    const onCurrencyClick = (index) => {
        setCurrencyIndex(index)
        setPrice(priceData[index])
    }

    const handleBlur = (e) => {
        validateEmail(email)
    }

    const submitEmail = (e) => {
        e.preventDefault();
        if (validateEmail(email)){
            nextStep()
        }
    }

    const submit = (e) => {
        e.preventDefault();
        if (validateEmail(email)){
            // onSendData()
            const data = {
                product,
                email,
                price,
                userData
            }
            setIsLoading(true)
            createInvoice(data)
        }
    }

    const paymentClick = (e) => {
            e.preventDefault();
            setInProgress(true)
            const interval = setInterval(updateContract, 5000);
            window.open(invoiceDate.paymentUrl, "_blank");
    }
    

    const validateEmail = (email) => {
        var isValid = false
        if (validator.isEmail(email)) {
            setEmailStatus("default")
            setEmailFooter("")
            isValid = true
        } else {
            setEmailStatus("error")
            setEmailFooter(errorMsg)
        }
        return isValid
    }

    return (
        <div className={"form"}>
                <Steps
                count={4}
                progress={step}
                />
                { step===1 && <div>
                <Section
                    footer=""
                    header="Наименование"
                >
                    <Cell >
                    <Headline
                        weight="2"
                    >
                        {product}
                    </Headline>
                    </Cell>
                </Section>
                <Section
                    header="Ваш E-mail"
                >
                    <Input 
                        placeholder="example@gmail.com" 
                        value={email}  
                        onChange={onChangeEmail} 
                        onBlur={handleBlur}
                        status={emailStatus}
                    />
                    <Cell>
                    <Subheadline
                        level="2"
                        // plain
                        weight="3"
                        style={{color: "red"}}
                    >
                        {emailFooter}
                    </Subheadline>
                    </Cell>
                </Section>             
                <Button
                    type="submit"
                    mode="filled"
                    size="l"
                    stretched
                    // loading={isLoading}
                    ref={{
                        current: '[Circular]'
                    }}
                    onClick={submitEmail}
                    >
                    Далее
                </Button>
                </div>
                }
                { step === 2 && <div>
                                    <Section
                                    header="Выберите валюту"
                                    footer=""
                                    >
                                    <InlineButtons >
                                        {priceData.map((price, index) => {
                                            return <InlineButtonsItem
                                                    text={price.currency}
                                                    mode={isButtonSelected(index)}
                                                    onClick={() => onCurrencyClick(index)}
                                                    key={index}
                                                />
                                        })}
                                    </InlineButtons>
                                    <Cell>
                                    <Subheadline
                                        level="2"
                                        // plain
                                        weight="3"
                                        style={{color: "red"}}
                                    >
                                    </Subheadline>
                                    </Cell>
                                </Section>
                                <Section
                                    header="Стоимость"
                                >
                                    <Cell >
                                    <Headline
                                        weight="2"
                                    >
                                        {price.amount+ " " + price.currency}
                                    </Headline>
                                    </Cell>
                                </Section>
                                <Button
                                    type="submit"
                                    mode="filled"
                                    size="l"
                                    stretched
                                    loading={isLoading}
                                    ref={{
                                        current: '[Circular]'
                                    }}
                                    onClick={submit}
                                    >
                                    Далее
                                </Button>
                                <Button
                                    // type="submit"
                                    mode="plain"
                                    size="l"
                                    stretched
                                    ref={{
                                        current: '[Circular]'
                                    }}
                                    onClick={() => previousStep()}
                                    >
                                    Вернуться назад
                                </Button>
                                                
                                </div>

                }
                { step === 3 && !inProgress && <div>
                                    <List
                                    // style={{
                                    // background: 'var(--tgui--secondary_bg_color)',
                                    // padding: '40px',
                                    // width: 500
                                    // }}
                                >
                                    <Section
                                        footer=""
                                        header="Проверьте правильность данных"
                                    >
                                        <Cell
                                            subhead="Наименование товара"
                                        >
                                            {product}
                                        </Cell>
                                        <Cell
                                            subhead="Ваш E-mail"
                                        >
                                            {email}
                                        </Cell>
                                        <Cell 
                                            subhead="Стоимость"
                                        >
                                            {price.amount+ " " + price.currency}
                                        </Cell>
                                    </Section>
                                </List>
                                <Button
                                    type="submit"
                                    mode="filled"
                                    size="l"
                                    stretched
                                    ref={{
                                        current: '[Circular]'
                                    }}
                                    onClick={paymentClick}
                                    >
                                    Перейти к оплате
                                </Button>
                                <Button
                                    // type="submit"
                                    mode="plain"
                                    size="l"
                                    stretched
                                    ref={{
                                        current: '[Circular]'
                                    }}
                                    onClick={() => previousStep()}
                                    >
                                    Вернуться назад
                                </Button>
                    </div>
                    }
                    { step === 3 && inProgress && <div>
                        <Placeholder
                            action={<a href={invoiceDate.paymentUrl} target="_blank">Открыть страницу оплаты</a>}
                            // description="Description"
                            header="Ожидание подтверждения оплаты..."
                        >
                            <img
                            width="300"
                            height="300"
                            alt="Telegram sticker"
                            className="blt0jZBzpxuR4oDhJc8s"
                            src="https://img-16.stickers.cloud/packs/ff93b479-6346-4ca5-a7cb-29a8f64dd505/webp/animated/90391c98-2bde-43d0-876e-7eb90d2d7a94.webp"
                            />
                        </Placeholder>
                        </div>}
                    { step === 4 && <div></div>}
        </div>
    );
};

export default Form;