import { useEffect } from 'react';
import './App.css';
import { useTelegram } from './hooks/useTelegram';
import Form from './components/Form/Form';


function App() {
  const {user, onClose, tg} = useTelegram();

  useEffect(() => {
    tg.ready();

  }, [])

  
  return (
    <div className="App">
      <Form></Form>
    </div>
  );
}

export default App;
